import { russianPaymentCardsNarrowedSet } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'ул. Социалистическая, д. 74',
		place: null,
		phoneNumbers: ['+7 (495) 980-17-27'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinoplan',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://itunes.apple.com/ru/app/киноплан/id697785442?mt=8',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.kinokassa',
		},
		payment: {
			active: true,
			card: russianPaymentCardsNarrowedSet,
		},
		maps: 'https://yandex.ru/maps/-/CBQbIKxQcC',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};


